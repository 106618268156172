export default function Services({ServicesH3, ServicesH4, children}) {
    return (
      <section id="Services" className="container bg-white m-auto mt-32 px-5 m-auto border-t">
        <div className="grid gap-5 sm:grid-cols-2 grid-cols-1 py-10">
            <div className="text-left"><p>Copyright'22 Marc Lilienthal</p></div>
            <div className="sm:text-right text-left"><p><a href="../imprint/">Imprint</a></p></div>            
        </div>
      </section>
    )
  }

  // <div className="text-center"><p>Created in <a className="underline" href="https://reactjs.org/" target="_blank" rel="noreferrer">React</a> and built with <a href="https://www.netlify.com/" className="underline" rel="noreferrer">Netlify</a></p></div>