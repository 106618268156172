// src/components/OnePost.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {Helmet} from 'react-helmet'
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import Blog from '../pages/Blog';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <div className="container m-auto pt-32 px-5">
      <div className="flex items-stretch">
      <Helmet>
        <title>{postData.title} | Marc Lilienthal</title>
        <meta name="description" content="SEO Description for Blog Post."/>
      </Helmet> 
        <div>
          <h1>{postData.title}</h1>
          <div>
            <img src={urlFor(postData.authorImage).width(20).height(20).url()} alt="Written by Marc Lilienthal" />
            <h4>{postData.name}</h4>
          </div>
        </div>
        <div>
          <img src={urlFor(postData.mainImage).width(500).url()} alt="" />
        </div>
      </div>
      <div>
        <BlockContent
          blocks={postData.body}
          projectId={sanityClient.clientConfig.projectId}
          dataset={sanityClient.clientConfig.dataset}
        />
      </div>
      <Blog></Blog>
    </div>
  );
}

// Replace <Blog> with a new "More Articles" Component