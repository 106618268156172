import marcLarge from '../images/MarcWebLarge.jpg';

export default function Hero({HeroH1, HeroH2, children}) {
    return (
      <section id="Hero" className="container bg-white m-auto text-left">
        <main className="lg:relative">
        <div className="mx-auto w-full max-w-7xl pt-16 pb-20 lg:py-48 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Hello! I am</span>{' '}
              <span className="block text-mred font-semibold xl:inline">Marc Lilienthal</span>
            </h1>
            <p className="mx-auto mt-3 max-w-md text-lg font-light sm:text-xl md:mt-5 md:max-w-3xl">
              {children}
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="https://calendly.com/mlilien/15min" rel="noreferrer"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-mred px-8 py-3 text-base font-medium text-white hover:bg-mdred md:py-4 md:px-10 md:text-lg" target="blank"
                >
                  Book a Meeting
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="https://www.linkedin.com/in/marclilienthal/" target="_blank" rel="noreferrer"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-mred hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
                >
                  Connect on LinkedIn
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-64 w-full  sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={marcLarge}
            alt=""
          />
        </div>
      </main>
      </section>
    )
  }

  // add main CTA <div className="mt-2 sm:mt-2 sm:flex sm:justify-start lg:justify-start font-roboto"> <div className="rounded-md shadow"> <a className="w-full flex items-center px-1 py-1 border-2 border-black text-base text-black hover:bg-white md:py-2 md:text-lg md:px-8" href="#" target="_blank" rel="noreferrer">LinkedIn</a></div></div>