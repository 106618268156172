import client01 from '../images/Tonies.svg';
import client02 from '../images/WOWTech.svg';
import client03 from '../images/IBMIX.svg';

export default function Logos({LogosH3}) {
    return (
      <section id="Logos" className="container bg-white m-auto pt-32 m-auto">

          <div className="bg-white border-t border-b border-mred">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <h2 className="text-3xl tracking-tight">
                      <span className="block xl:inline">I hit the ground running for these</span>{' '}
                      <span className="block text-mred font-semibold xl:inline">great companies</span>
                      <span> and many more</span>
                </h2>
                <div className="mt-8 flow-root self-center lg:mt-0">
                  <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                    <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                      <img
                        className="h-32"
                        src={client01}
                        alt="Tonies"
                      />
                    </div>
                    <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                    <img  className="h-32" src={client02} target="_blank" alt="WOW Tech"></img>
                    </div>
                    <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                    <img className="h-32"  src={client03} target="_blank" alt="IBM IX"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </section>
    )
  }

  // Build in Slider maybe this one: https://0ru2k.sse.codesandbox.io/
  //import client04 from '../images/Coyo.svg';
//import client05 from '../images/ZurRose.svg';
//import client06 from '../images/Vorwerk.svg';