import marcLogo from '../images/marc_logo.svg';

const navigation = [
  { name: 'Calendly', href: 'https://calendly.com/mlilien/', alt: 'Calendly Overview for Online Booking' },
]

export default function header({HeaderH3}) {
    return (
      <header className="bg-white">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-mred py-6 lg:border-none">
          <div className="flex items-center">
            <a href="https//lilienthal.io/">
              <span className="sr-only">Workflow</span>
              <img
                className="h-32 w-auto"
                src={marcLogo}
                alt="Logo Marc."
              />
            </a>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} alt={link.alt} className="text-base font-medium text-white hover:text-indigo-50">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          
        </div>
        <div className="flex flex-wrap justify-center space-x-6 py-4 lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} alt={link.alt} className="text-base font-medium text-white hover:text-indigo-50">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
    )
  }

  // Build in Slider maybe this one: https://0ru2k.sse.codesandbox.io/