import React from "react";
import {Helmet} from 'react-helmet'
import Footer from '../components/Footer';
import Header from "../components/Header";
import Hero from "../components/Hero";
import Logos from "../components/Logos";


export default function Mainpage() {
  return (
    <div className="Mainpage">
      <Helmet>
        <title>Marc Lilienthal - Digital Product Leader</title>
        <meta name="description" content="Looking for an Program or Product Manager to lead your Product Team(s)? Let's talk."/>
      </Helmet> 
      <Header></Header>
      <Hero>I am a Digital Product Leader specialized in IoT and E-Commerce. Wether you want to create something new, improve a product or get back to speed: challenge me.</Hero>
      <Logos LogosH3="I hit the ground running for these great companies"></Logos>
      
     
      <Footer></Footer>
    </div>
  );
}

//import SHero from '../components/SHero';
//import Clients from '../components/Clients';
//import Services from '../components/Services';
//import Projects from '../components/Projects';
//import Education from '../components/Education';
//import Contact from "../components/Contact";
//import Features from "../components/Features";
//<Features></Features>
// <Contact></Contact>
// <SHero HeroH1="Marc Lilienthal" HeroH2="Digital Product Leader.">I am an IoT and E-Commerce specialist…  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</SHero>
// <Clients ClientsH3="I hit the ground running for these great companies"></Clients>
// <Services ServicesH3="What i offer" ServicesH4="Digital Product Leadership">
//  <h4>Skill Cloud</h4>
//  <p>#HeadlessCommerce, #IoT, #Apps, #CMS, #AgileMethods, #MobileFirst, #DataAnalysis, #AtomicDesign, #UserResearch, #UXDesign, #TagManagement, #TechnicalSEO, #Microservices, #SAPHybris, #Commercetools, #Shopify, #Contentful, #AdobeExperienceManager and many more.</p>
//</Services>
//<Projects ProjectsH3="Product Experience"></Projects>
//<Education EducationH3="Education and Certifications">
//  Let's tick some boxes: I hold an MBA, certifications for Agile Methods and constantly improve my Data Analysis Skills. On the side i started to learn REACT to really understand what a 'component' is.
//</Education>