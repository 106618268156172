// src/components/AllPosts.js

import React, { useEffect, useState } from "react";
import {Helmet} from 'react-helmet'
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div id="AllPosts" className="container m-auto pt-32 px-5 ">
      <Helmet>
        <title>Product Management on steroids</title>
        <meta name="description" content="My latest thoughts on Product Managagement, clients from hell and why i think most people should finally get their shit together."/>
      </Helmet>
      <h1>Digital Product Reads </h1>
      <h2>My latest thoughts on Product Managagement, clients from hell and why i think most people should finally get their shit together.</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-32">
          {allPostsData &&
            allPostsData.map((post, index) => (
              <Link to={"/blog/" + post.slug.current} key={post.slug.current}>
                <span
                  className="block h-64 relative leading-snug bg-white"
                  key={index}
                >
                  <img
                    className="w-full h-full rounded-r object-cover absolute"
                    src={post.mainImage.asset.url}
                    alt=""
                  />
                  <span
                    className="block relative h-full flex justify-end items-end pr
                      -4 pb-4"
                  >
                    <h3
                      className="text-lg px-3 py-4 rounded"
                    >
                      {post.title}
                    </h3>
                  </span>
                </span>
              </Link>
            ))}
      </div>
    </div>
  );
}