import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AllPosts from "./pages/Blog.js";
import OnePost from "./pages/OnePost.js";
import Imprint from "./pages/Imprint.js";
import Mainpage from "./pages/Mainpage.js";

function App() {
  return (
    <BrowserRouter>
      
        <Route path="/" exact>
          <Mainpage />
        </Route>
        <Route path="/blog/" exact>
          <AllPosts />
        </Route>
        <Route path="/imprint/" exact>
          <Imprint />
        </Route>
        <Route path="/blog/:slug" exact>
          <OnePost />
        </Route>
      
    </BrowserRouter>
  );
}

export default App;
